import { type FigureWithoutPlacement, type Point } from "../type";import { type Marking } from "../../../type";
import calcMeasurements from "../calcFigureMeasurements";

/**
 *
 * @param markingPoints
 * @param fontSize
 * @returns
 */
const getMarkingPointsFigure = (
  marking: Marking,
  markingPointsPosition: Marking["points"],
  height: number
): FigureWithoutPlacement => {
  const points: Point[] = markingPointsPosition.map((x) => [x, 0]);
  const shape = (
    <>
      {points.map(([x, y], idx) => (
        <text
          key={`marking_point_${x}`}
          x={x}
          y={y}
          dominantBaseline="text-before-edge"
        >
          {marking.points[idx]}
        </text>
      ))}
    </>
  );
  const { width } = calcMeasurements(points);

  return {
    shape,
    width,
    height,
  };
};

export default getMarkingPointsFigure;
