import { useState, useEffect } from "react";
import round from "../../utils/round";
import { Unit } from "./units";
import {
  MarkingPosition,
  CurveParameter,
  type CurveParameters,
  type CutParameters,
  type CurveCalculatorState,
  type SetMarkingParameterFunc,
  type Marking,
} from "./type";
import useSVGPlan from "./hooks/useSVGPlan";

const defaultState: CurveCalculatorState = {
  language: undefined,
  unit: Unit.millimeter,
  isCalculated: false,
  curveParametersWasChanged: false,
  curveParameters: {
    curveInsideRadius: 0,
    curveOutsideRadius: 0,
    materialThickness: 0,
    curveAngle: 90,
    cutWidth: 0,
    cutDepth: 0,
  },
  marking: {
    points: [],
    startPosition: 0,
    markingPosition: MarkingPosition.cutEdge,
  },
  cutParameters: {
    countOfCuts: 0,
    insideCurveLength: 0,
    outsideCurveLength: 0,
    realOutsideCurveLength: 0,
    intervalWidth: 0,
  },
  svgParameters: {
    width: 0,
    height: 0,
    grid: [],
  },
};

export const useWoodCurveCalculator = (
  initialState?: Partial<CurveCalculatorState>
) => {
  const [state, setState] = useState<CurveCalculatorState>({
    ...defaultState,
    ...initialState,
  });
  const getSVGPlan = useSVGPlan();

  /**
   *
   */
  const resetCurveParameters = () => {
    setState((prev) => ({
      ...prev,
      curveParameters: defaultState.curveParameters,
    }));
  };

  /**
   *
   * @param unit
   */
  const setUnit = (unit: Unit) => {
    setState((prev) => ({ ...prev, unit }));
  };

  /**
   *
   */
  const handleCalculateCutParameters = (curveParameters: CurveParameters) => {
    const { marking: prevMarking } = state;

    const cutParameters = calculateCutParameters(curveParameters);
    const markingPoints = calculateMarkingPoints({
      ...cutParameters,
      ...curveParameters,
      ...prevMarking,
    });
    const marking = { ...prevMarking, points: markingPoints };
    const svgParameters = getSVGPlan({
      ...cutParameters,
      ...curveParameters,
      ...marking,
    });

    setState((prev) => ({
      ...prev,
      curveParameters,
      cutParameters,
      marking,
      svgParameters,
      isCalculated: true,
    }));
  };

  /**
   *
   * @param name
   * @param value
   */
  const setMarkingParameter: SetMarkingParameterFunc = (name, value) => {
    setState((prev) => ({
      ...prev,
      marking: { ...prev.marking, [name]: value },
    }));
  };

  /**
   *
   * @param r
   * @param alpha
   * @returns
   */
  const getCurveLength = (
    r:
      | CurveParameters["curveInsideRadius"]
      | CurveParameters["curveOutsideRadius"],
    alpha: CurveParameters["curveAngle"]
  ) => {
    return (Math.PI * r * alpha) / 180;
  };

  const calculateCutParameters = ({
    curveInsideRadius,
    curveOutsideRadius,
    curveAngle,
    cutWidth,
  }: CurveParameters): CutParameters => {
    const insideCurveLength = getCurveLength(curveInsideRadius, curveAngle);
    const outsideCurveLength = getCurveLength(curveOutsideRadius, curveAngle);
    const lengthDelta = outsideCurveLength - insideCurveLength;
    const countOfCuts = Math.ceil(lengthDelta / cutWidth);
    const intervalWidth = round(insideCurveLength / (countOfCuts - 1));
    const realOutsideCurveLength = calculateRealCurveLength(
      cutWidth,
      intervalWidth,
      countOfCuts
    );

    return {
      countOfCuts,
      intervalWidth,
      realOutsideCurveLength,
      insideCurveLength: round(insideCurveLength),
      outsideCurveLength: round(outsideCurveLength),
    };
  };

  /**
   *
   * @param param0
   * @returns
   */
  const calculateMarkingPoints = ({
    countOfCuts,
    intervalWidth,
    markingPosition,
    startPosition,
    cutWidth,
    outsideCurveLength,
    realOutsideCurveLength,
  }: Pick<CurveParameters, CurveParameter.cutWidth> &
    CutParameters &
    Omit<Marking, "points">): Marking["points"] => {
    const startPointOffset = (outsideCurveLength - realOutsideCurveLength) / 2; // Велечина смещения первой точки учитывая разницу фактической и рассчётной длины изгиба
    const distanceBetweenPoints = intervalWidth + cutWidth;
    let startPoint = startPosition + startPointOffset;

    if (markingPosition === MarkingPosition.cutCenter) {
      startPoint = startPoint + cutWidth / 2;
    }

    const points = [startPoint];

    for (let i = 0; i < countOfCuts - 1; i++) {
      const prevPoint = points[i];
      points.push(round(prevPoint + distanceBetweenPoints));
    }

    return points.map((point) => round(point));
  };

  /**
   *
   * @param cutWidth
   * @param intervalWidth
   * @param countOfCuts
   * @returns
   */
  const calculateRealCurveLength = (
    cutWidth: CurveParameters["cutWidth"],
    intervalWidth: CutParameters["intervalWidth"],
    countOfCuts: CutParameters["countOfCuts"]
  ) => {
    return round(cutWidth * countOfCuts + intervalWidth * (countOfCuts - 1));
  };

  /**
   * Отслеживаем изменения параметров разметки и пересчитываем точки разметки
   */
  useEffect(() => {
    if (state.isCalculated) {
      setState((prev) => {
        const { cutParameters, curveParameters, marking } = prev;

        const points = calculateMarkingPoints({
          ...cutParameters,
          ...curveParameters,
          ...marking,
        });
        const svgParameters = getSVGPlan({
          ...cutParameters,
          ...curveParameters,
          ...marking,
          points,
        });

        return {
          ...prev,
          marking: {
            ...marking,
            points,
          },
          svgParameters,
        };
      });
    }
  }, [
    state.marking.startPosition,
    state.marking.markingPosition,
    state.isCalculated,
    getSVGPlan,
  ]);

  return {
    state,
    resetCurveParameters,
    setUnit,
    setMarkingParameter,
    handleCalculateCutParameters,
  };
};
