import { type Point } from "./type";

/**
 *
 * @param points
 * @returns
 */
const calcMeasurements = (points: Point[]) => {
  // Складываем все координаты X и Y в отдельные массивы
  const [X_s, Y_s] = points.reduce(
    ([X_s, Y_s], [x, y]) =>
      [
        [...X_s, x],
        [...Y_s, y],
      ] as [number[], number[]],
    [[], []] as [number[], number[]]
  );

  const maxX = Math.max(...X_s);
  const minX = Math.min(...X_s);
  const maxY = Math.max(...Y_s);
  const minY = Math.min(...Y_s);

  return {
    width: Math.abs(maxX - minX),
    height: Math.abs(maxY - minY),
  };
};

export default calcMeasurements;
