interface LabelProps extends React.ComponentProps<"label"> {
  required?: boolean;
}

const Label = (props: LabelProps) => {
  return (
    <label
      {...props}
      className={`form__label ${props.required ? "form_label_required" : ""} ${
        props.className || ""
      } `}
    />
  );
};

export default Label;
