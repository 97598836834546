import { type SVGParameters, CurveCalculatorState } from "../type";
import "./style.scss";

const woodTexture = require("../assets/images/wood_texture.jpg");

interface ShapeProps extends SVGParameters {
  isCalculated: CurveCalculatorState["isCalculated"];
}

const SVG = ({
  visible = false,
  width,
  height,
  grid,
}: { visible: boolean } & SVGParameters) => {
  if (!visible)
    return (
      <p>
        Сначала нужно заполнить обязательные поля, отмеченные знаком{" "}
        <code>
          '<span className="required_mark" />'
        </code>{" "}
        и нажать кнопку <code>'Рассчитать'</code>
      </p>
    );

  return (
    <svg
      className="shape__svg_parent"
      width={"100%"}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      <defs>
        <pattern
          id="wood_texture_pattern"
          height="100%"
          width="100%"
          patternContentUnits="objectBoundingBox"
        >
          <image
            height="1"
            width="1"
            preserveAspectRatio="none"
            xlinkHref={woodTexture}
          />
        </pattern>
      </defs>

      {grid.map((figure, idx) =>
        figure! ? (
          <svg
            className="shape__svg_child"
            key={`figure_${idx}`}
            x={figure.startCoords[0]}
            y={figure.startCoords[1]}
          >
            {figure.shape}
          </svg>
        ) : null
      )}
    </svg>
  );
};

const Shape = ({ isCalculated, ...rest }: ShapeProps) => {
  return (
    <div className="wood-curve-calculator__shape wood-curve-calculator__child">
      <h3>Чертёж</h3>

      {isCalculated && (
        <div className="shape__plan-too-small_info">
          <span className="circle-exclamation">!</span>Да, я знаю, что на
          мобилке мелковато, но и ты не подумал повернуть экран горизонтально
        </div>
      )}

      <SVG
        visible={isCalculated}
        {...rest}
      />
    </div>
  );
};

export default Shape;
