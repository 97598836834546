import { APP_NAME } from "./constants";
import { useLayoutEffect } from "react";
import WoodCurveCalculator, {
  useWoodCurveCalculator,
} from "./feature/wood-curve-calculator";

function App() {
  const {
    state,
    setUnit,
    setMarkingParameter,
    resetCurveParameters,
    handleCalculateCutParameters,
  } = useWoodCurveCalculator();

  useLayoutEffect(() => {
    document.title = APP_NAME;
  }, []);

  return (
    <>
      <WoodCurveCalculator
        state={state}
        setUnit={setUnit}
        resetCurveParameters={resetCurveParameters}
        setMarkingParameter={setMarkingParameter}
        handleCalculateCutParameters={handleCalculateCutParameters}
      />
    </>
  );
}

export default App;
