import { type ValidationRule as HookFormValidationRule } from "react-hook-form";
export enum ValidationRule {
  required = "required",
  min = "min",
  max = "max",
}

export type ValidationValue = number | string | boolean;

export const ValidationMessage: Record<
  ValidationRule,
  string | ((val?: ValidationValue) => string)
> = {
  [ValidationRule.required]: "Поле должно быть заполнено",
  [ValidationRule.min]: (min) => `Значение не может быть меньше чем ${min}`,
  [ValidationRule.max]: (max) => `Значение не может быть больше чем ${max}`,
};

export const getValidationRule = (
  rule: `${ValidationRule}`,
  value: any
): HookFormValidationRule<typeof value> => {
  const validationMessage = ValidationMessage[rule];
  const message =
    typeof validationMessage === "function"
      ? validationMessage(value)
      : validationMessage;

  return {
    value,
    message,
  };
};
