import { type Marking } from "../../../type";import { type Point } from "../type";
import calcMeasurements from "../calcFigureMeasurements";

/**
 *
 * @param marking
 * @returns
 */
const getMarkingLinesFigure = (
  marking: Marking,
  lineHeight: number,
  additionalHeight: number = 0
) => {
  const points: Point[] = marking.points.map((x) => [x, 0]);
  const shape = (
    <>
      {points.map(([x, y]) => (
        <line
          key={`marking_line_${x}`}
          x1={x}
          y1={y}
          x2={x}
          y2={y + lineHeight + additionalHeight}
        />
      ))}
    </>
  );
  const { width } = calcMeasurements(points);

  return {
    shape,
    width,
    height: lineHeight,
  };
};

export default getMarkingLinesFigure;
