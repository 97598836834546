interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  if (!message) return null;

  return <div className="form__error-message">{message}</div>;
};

export default ErrorMessage;
