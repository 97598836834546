import { forwardRef } from "react";import ErrorMessage from "../ErrorMessage";

const InputNumber = forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input"> & { error?: string }
>((props, ref) => {
  return (
    <div
      className={`form__input-number ${props.className || ""} ${
        props.error ? "form__input-number_error" : ""
      }`}
    >
      <input
        ref={ref}
        {...props}
        type="number"
        inputMode="numeric"
      />

      <ErrorMessage message={props.error} />
    </div>
  );
});

export default InputNumber;
