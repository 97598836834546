import { type CurveParameters } from "../../../type";/**
 *
 * @param materialThickness
 */
const getMaterialThicknessFigure = (
  materialThickness: CurveParameters["materialThickness"],
  lineHeight: number
) => {
  // Start coordinates
  const x0 = 0;
  const y0 = 0;
  const width = x0 + lineHeight;
  const height = y0 + materialThickness;

  const topLineCoords = { x1: x0, y1: y0, x2: width, y2: y0 };
  const bottomLineCoords = {
    x1: x0,
    y1: height,
    x2: width,
    y2: height,
  };

  const shape = (
    <>
      <line {...topLineCoords} />
      <line {...bottomLineCoords} />
      <line
        x1={topLineCoords.x2}
        y1={topLineCoords.y2}
        x2={bottomLineCoords.x2}
        y2={bottomLineCoords.y2}
      />
      <text
        x={width + 1}
        y={height / 2}
        textAnchor="start"
        dominantBaseline="central"
        className="material_thickness_measure"
      >
        {materialThickness}
      </text>
    </>
  );

  return {
    shape,
    width,
    height: materialThickness,
  };
};

export default getMaterialThicknessFigure;
