import round from "../../../../utils/round";import { type Marking } from "../../type";

/**
 * Точки разметки смещаются к нулю
 * @param markingPoints
 * @returns
 */
const shiftMarkingPoints = (
  markingPoints: Marking["points"],
  firstPoint: number = 0
) => {
  const shift = firstPoint + -markingPoints[0];
  return markingPoints.map((point) => round(point + shift));
};

export default shiftMarkingPoints;
