import { type FigureWithoutPlacement, type Point } from "../type";
type Rectangle = {
  width: number;
  height: number;
};
type Cut = {
  width: number;
  depth: number;
  count: number;
};
type Indent = number;

/**
 * Получаем координаты базовой формы пропила с отступом
 * @param cut
 * @param indent
 * @returns
 */
const getCutWithIndentShapePoints = (cut: Cut, indent: Indent): Point[] => {
  return [
    [0, cut.depth],
    [cut.width, cut.depth],
    [cut.width, 0],
    [cut.width + indent, 0],
    [cut.width + indent, cut.depth],
  ];
};

const getWoodFigure = (
  rect: Rectangle,
  cut: Cut,
  indent: Indent
): FigureWithoutPlacement => {
  const baseShapePoints = getCutWithIndentShapePoints(cut, indent);
  const points: Point[] = [...baseShapePoints];
  const shift = indent + cut.width;

  for (let i = 1; i < cut.count - 1; i++) {
    const shiftedBaseShape: Point[] = baseShapePoints.map(([x, y]) => [
      x + shift * i,
      y,
    ]);
    points.push(...shiftedBaseShape);
  }

  // Добавляем последний пропил и замыкаем фигуру
  points.push(
    [rect.width, cut.depth],
    [rect.width, rect.height],
    [0, rect.height]
  );

  const shape = (
    <polygon className="wood-figure" points={points.flat(2).join(" ")} />
  );

  return {
    ...rect,
    shape,
  };
};

export default getWoodFigure;
