import { useCallback } from "react";import sum from "lodash.sum";
import {
  type Point,
  type GridTemplate,
  type CellTemplate,
  type Grid,
} from "./type";
import {
  MarkingPosition,
  type CurveParameters,
  type CutParameters,
  type Marking,
} from "../../type";
import getWoodFigure from "./figures/WoodFigure";
import getMarkingPointsFigure from "./figures/MarkingPoints";
import getMarkingLinesFigure from "./figures/MarkingLines";
import getCurveLengthFigure from "./figures/CurveLength";
import getMaterialThicknessFigure from "./figures/MaterialThickness";
import shiftMarkingPoints from "./shiftMarkingPoints";

type GridMeasurements = {
  colsWidths: number[]; // Ширины колонок
  rowsHeights: number[]; // Высоты строк
};

type SVGPlanProps = {
  lineHeight: number;
  gridGap: [rowGap: number, colGap: number];
};

type Cells = {
  woodFigure: CellTemplate;
  markingPointsFigure: CellTemplate;
  markingLinesFigure: CellTemplate;
  curveLengthFigure: CellTemplate;
  materialThicknessFigure: CellTemplate;
};

type Params = CurveParameters & CutParameters & Marking;

const defaultProps: SVGPlanProps = {
  lineHeight: 5,
  gridGap: [1, 1],
};

const useSVGPlan = (props?: SVGPlanProps) => {
  const planProps: SVGPlanProps = { ...props, ...defaultProps };

  const { gridGap, lineHeight } = planProps;
  const [rowGap, colGap] = gridGap;

  const getShiftedMarkingPoints = ({
    markingPosition,
    cutWidth,
    points,
  }: Params) => {
    let firstPoint = 0;

    if (markingPosition === MarkingPosition.cutCenter) {
      firstPoint = firstPoint + cutWidth / 2;
    }

    return shiftMarkingPoints(points, firstPoint);
  };

  /**
   *
   * @param params
   * @returns
   */
  const getCells = useCallback(
    (params: Params): Cells => {
      const {
        points,
        materialThickness,
        realOutsideCurveLength,
        countOfCuts,
        cutWidth,
        cutDepth,
        intervalWidth,
        startPosition,
        markingPosition,
      } = params;

      const shiftedPoints = getShiftedMarkingPoints(params);
      const marking = { points, startPosition, markingPosition };
      const additionalLineHeight =
        markingPosition === MarkingPosition.cutCenter ? cutDepth : undefined;

      const woodFigure = getWoodFigure(
        {
          height: materialThickness,
          width: realOutsideCurveLength,
        },
        {
          count: countOfCuts,
          width: cutWidth,
          depth: cutDepth,
        },
        intervalWidth
      );
      const markingPointsFigure = getMarkingPointsFigure(
        marking,
        shiftedPoints,
        lineHeight
      );
      const markingLinesFigure = getMarkingLinesFigure(
        { ...marking, points: shiftedPoints },
        lineHeight,
        additionalLineHeight
      );
      const curveLengthFigure = getCurveLengthFigure(
        realOutsideCurveLength,
        lineHeight
      );
      const materialThicknessFigure = getMaterialThicknessFigure(
        materialThickness,
        lineHeight
      );

      return {
        woodFigure,
        markingPointsFigure,
        markingLinesFigure,
        curveLengthFigure,
        materialThicknessFigure,
      };
    },
    [lineHeight]
  );

  /**
   * Получаем высоты строк и ширины колонок
   * @param gridTemplate
   * @returns
   */
  const getGridMeasurements = (
    gridTemplate: GridTemplate
  ): GridMeasurements => {
    const rowsHeights: number[] = [];
    const colsWidths: number[] = [];

    for (let i = 0; i < gridTemplate.length; i++) {
      const row = gridTemplate[i];

      for (let j = 0; j < row.length; j++) {
        const figure = row[j];

        if (!figure) continue;

        if (!rowsHeights[i] || figure.height > rowsHeights[i]) {
          rowsHeights[i] = figure.height;
        }
        if (!colsWidths[j] || figure.width > colsWidths[j]) {
          colsWidths[j] = figure.width;
        }
      }
    }

    return {
      rowsHeights,
      colsWidths,
    };
  };

  /**
   *
   * @param template
   * @param measurements
   * @param startCoords
   * @returns
   */
  const getGrid = useCallback(
    (
      template: GridTemplate,
      measurements: GridMeasurements,
      startCoords: Point = [0, 0]
    ): Grid => {
      let xCoord = startCoords[0];
      let yCoord = startCoords[1];

      const { rowsHeights, colsWidths } = measurements;
      const cells: Grid = new Array(rowsHeights.length)
        .fill(null)
        .map(() => []);

      for (let i = 0; i < template.length; i++) {
        const row = template[i];

        for (let j = 0; j < row.length; j++) {
          const figure = row[j];

          if (!figure) {
            cells[i][j] = null;
          } else {
            cells[i][j] = {
              ...figure,
              startCoords: [xCoord, yCoord],
            };

            xCoord = xCoord + colsWidths[j] + colGap;
          }
        }

        xCoord = startCoords[0];
        yCoord = yCoord + rowsHeights[i] + rowGap;
      }

      return cells;
    },
    [colGap, rowGap]
  );

  /**
   *
   * @param params
   * @returns
   */
  const getSVGPlan = useCallback(
    (params: Params) => {
      const {
        woodFigure,
        markingPointsFigure,
        markingLinesFigure,
        curveLengthFigure,
        materialThicknessFigure,
      } = getCells(params);

      const template: GridTemplate = [
        [markingPointsFigure, null],
        [markingLinesFigure, null],
        [woodFigure, materialThicknessFigure],
        [curveLengthFigure, null],
      ];

      const measurements = getGridMeasurements(template);
      const grid = getGrid(template, measurements);

      return {
        width:
          sum(measurements.colsWidths) +
          colGap * (measurements.colsWidths.length - 1),
        height:
          sum(measurements.rowsHeights) +
          rowGap * (measurements.rowsHeights.length - 1),
        grid: grid.flat(2),
      };
    },
    [colGap, rowGap, getCells, getGrid]
  );

  return getSVGPlan;
};

export default useSVGPlan;
