import { Fragment } from "react";

type Row = {
  title: string;
  description: string;
};

interface PopupDescriptionProps {
  rows: Row[];
}

const PopupDescription = ({ rows }: PopupDescriptionProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "auto min-content 1fr",
      }}
    >
      {rows.map(({ title, description }, idx) => (
        <Fragment key={idx}>
          <div>
            <strong>{title}</strong>
          </div>
          <div>-</div>
          <div>{description}</div>
        </Fragment>
      ))}
    </div>
  );
};

export default PopupDescription;
