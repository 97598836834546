export enum Unit {
    millimeter = "millimeter",
    centimeter = "centimeter",
    meter = "meter",
}

export const ShortUnitName: Record<Unit, string> = {
    [Unit.millimeter]: "мм",
    [Unit.centimeter]: "см",
    [Unit.meter]: "м"
} as const

export const LongUnitName: Record<Unit, [string, string]> = {
    [Unit.millimeter]: ["миллиметр", "миллиметры"],
    [Unit.centimeter]: ["сантиметр", "сантиметры"],
    [Unit.meter]: ["метр", "метры"]

} as const

export type UnitOption = {
    value: Unit,
    text: string
}

export const UnitOptions: UnitOption[] = Object.values(Unit).map((value)=> ({value, text: `${LongUnitName[value][1]}, ${ShortUnitName[value]}`})) 