import Trigger, { type TriggerProps } from "rc-trigger";
import TooltipPopup from "./popup";
import "rc-trigger/assets/index.css"
import "./style.scss"

const defaultPopupAlign = {
    offset: [12, 0]
};

const builtinPlacements = {
    left: {
        points: ['cr', 'cl'],
      },
      leftTop: {
        points: ["tr", 'tl']
      },
      leftBottom: {
        points: ["br", 'bl']
      },
      right: {
        points: ['cl', 'cr'],
      },
      rightTop: {
        points: ["tl", "tr"]
      },
      rightBottom: {
        points: ['bl', 'br']
      },
      top: {
        points: ['bc', 'tc'],
      },
      bottom: {
        points: ['tc', 'bc'],
      },
      topLeft: {
        points: ['bl', 'tl'],
      },
      topRight: {
        points: ['br', 'tr'],
      },
      bottomRight: {
        points: ['tr', 'br'],
      },
      bottomLeft: {
        points: ['tl', 'bl'],
      },
}

const Tooltip = ({action = ["focus"], popupAlign = defaultPopupAlign, popupPlacement = "rightTop", ...props}: TriggerProps) => {
    return <Trigger action={action} popupAlign={popupAlign} builtinPlacements={builtinPlacements} popupPlacement={popupPlacement} {...props} />
}

export { TooltipPopup }
export default Tooltip