import Label from "../../../components/Form/Label";import Select from "../../../components/Form/Select";
import InputNumber from "../../../components/Form/InputNumber";
import Tooltip, { TooltipPopup } from "../../../components/Tooltip";
import { Unit, ShortUnitName } from "../units";
import {
  MarkingPositionOptions,
  type SetMarkingParameterFunc,
  type Marking as MarkingType,
  MarkingPosition,
} from "../type";
import "./style.scss";

const Marking = ({
  unit,
  points,
  markingPosition,
  startPosition,
  setMarkingParameter,
}: MarkingType & {
  setMarkingParameter: SetMarkingParameterFunc;
  unit: Unit;
}) => {
  const unitName = ShortUnitName[unit];

  /**
   *
   * @param points
   * @returns Возвращает true когда массив точек пустой
   */
  const emptyPointsChecker = (points: MarkingType["points"]): boolean => {
    if (points.length <= 1 && !points[0]) return true;

    return false;
  };

  return (
    <div className="wood-curve-calculator__marking">
      <h3>Разметка</h3>

      <form
        className="marking__parameters"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="parameters__form-item">
          <Label>Разметка по</Label>
          <Tooltip
            popupVisible={false}
            popupPlacement="right"
            popup={<TooltipPopup />}
          >
            <Select
              value={markingPosition}
              name="markingPosition"
              onChange={({ target }) =>
                setMarkingParameter(
                  target.name as keyof MarkingType,
                  target.value as MarkingPosition
                )
              }
            >
              {MarkingPositionOptions.map(({ value, text }) => (
                <option
                  key={value}
                  value={value}
                >
                  {text}
                </option>
              ))}
            </Select>
          </Tooltip>
        </div>

        <div className="parameters__form-item">
          <Label>Начальная точка разметки</Label>
          <Tooltip
            popupPlacement="bottomLeft"
            popupAlign={{ offset: [0, 6] }}
            popup={<TooltipPopup></TooltipPopup>}
            popupVisible={false}
          >
            <InputNumber
              value={startPosition}
              name="startPosition"
              onChange={({ target }) =>
                setMarkingParameter(
                  target.name as keyof MarkingType,
                  Number(target.value)
                )
              }
              min={0}
              max={10_000}
            />
          </Tooltip>
        </div>
      </form>

      <div className="marking__points">
        {!emptyPointsChecker(points) &&
          points.map((point) => (
            <span
              className="points__point"
              key={`point_${point}`}
            >
              {point}
              {unitName}
            </span>
          ))}
      </div>
    </div>
  );
};

export default Marking;
