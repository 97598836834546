interface TooltipPopupProps {
    title?: string;
    img?: any
    description?: React.ReactNode | React.ReactNode[];
}

const TooltipPopup = ({ title, img, description }: TooltipPopupProps) => {
    return (
        <div className="tooltip__popup">
            <h6>{title}</h6>

            {img! && <div className="popup__image" style={img} />}

            {description}
        </div>
    )
}

export default TooltipPopup;