import { APP_NAME } from "../../constants";
import Form from "./Form";
import Result from "./Result";
import Shape from "./Shape";
import {
  type CurveCalculatorState,
  type SetUnitFunc,
  type SetMarkingParameterFunc,
  type CurveParameters,
} from "./type";
import "./style/fonts.scss";
import "./index.scss";

interface WoodCurveCalculatorProps {
  state: CurveCalculatorState;
  setUnit: SetUnitFunc;
  setMarkingParameter: SetMarkingParameterFunc;
  resetCurveParameters: () => void;
  handleCalculateCutParameters: (data: CurveParameters) => void;
}

const WoodCurveCalculator = ({
  state,
  setUnit,
  resetCurveParameters,
  setMarkingParameter,
  handleCalculateCutParameters,
}: WoodCurveCalculatorProps) => {
  return (
    <div className="wood-curve-calculator">
      <h1 className="wood-curve-calculator__header">{APP_NAME}</h1>

      <p className="wood-curve-calculator__description">
        Этот калькулятор поможет рассчитать количество пропилов, необходимых для
        изгиба древесины на заданый угол
      </p>

      <Form
        unit={state.unit}
        setUnit={setUnit}
        onSubmit={handleCalculateCutParameters}
        onReset={resetCurveParameters}
        {...state.curveParameters}
      />

      <Result
        unit={state.unit}
        marking={state.marking}
        setMarkingParameter={setMarkingParameter}
        {...state.cutParameters}
      />

      <Shape
        isCalculated={state.isCalculated}
        {...state.svgParameters}
      />
    </div>
  );
};

export { useWoodCurveCalculator } from "./useWoodCurveCalculator";
export default WoodCurveCalculator;
