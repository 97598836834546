import { forwardRef } from "react";import ErrorMessage from "../ErrorMessage";

const Select = forwardRef<
  HTMLSelectElement,
  React.ComponentProps<"select"> & { error?: string }
>((props, ref) => {
  return (
    <div
      className={`form__select ${props.className || ""} ${
        props.error ? "form__select_error" : ""
      }`}
    >
      <select
        ref={ref}
        {...props}
      />

      <ErrorMessage message={props.error} />
    </div>
  );
});

export default Select;
