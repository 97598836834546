import { type CutParameters } from "../../../type";
/**
 *
 * @param curveLength
 * @returns
 */
const getCurveLengthFigure = (
  curveLength: CutParameters["realOutsideCurveLength"],
  lineHeight: number
) => {
  // Start coordinates
  const x0 = 0;
  const y0 = 0;
  const width = x0 + curveLength;
  const height = y0 + lineHeight;

  const leftLineCoords = { x1: x0, y1: y0, x2: x0, y2: height };
  const rightLineCoords = {
    x1: width,
    y1: y0,
    x2: width,
    y2: height,
  };

  const shape = (
    <g>
      <line {...leftLineCoords} />
      <line {...rightLineCoords} />
      <line
        x1={leftLineCoords.x2}
        y1={leftLineCoords.y2}
        x2={rightLineCoords.x2}
        y2={rightLineCoords.y2}
      />
      <text
        x={width / 2}
        y={height}
        dominantBaseline="text-before-edge"
        textAnchor="middle"
      >
        {curveLength}
      </text>
    </g>
  );

  return {
    shape,
    width: curveLength,
    height: lineHeight,
  };
};

export default getCurveLengthFigure;
