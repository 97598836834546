import { useState, useEffect, useCallback } from "react";import { useForm } from "react-hook-form";
import throttle from "lodash.throttle";
import Label from "../../../components/Form/Label";
import InputNumber from "../../../components/Form/InputNumber";
import Select from "../../../components/Form/Select";
import Button from "../../../components/Form/Button";
import Tooltip, { TooltipPopup } from "../../../components/Tooltip";
import PopupDescription from "./PopupDescription";
import { type Unit, UnitOptions, ShortUnitName } from "../units";
import { type CurveParameters, type SetUnitFunc } from "../type";
import { getValidationRule } from "./validation";
import "./style.scss";

const materialThicknessPic = require("../assets/images/material_thickness.jpg");
const curveAnglePic = require("../assets/images/curve_angle.jpg");
const curveRadiusesPic = require("../assets/images/curve_radiuses.jpg");
const cutParametersPic = require("../assets/images/cut_parameters.jpg");

interface FormProps extends CurveParameters {
  unit: Unit;
  setUnit: SetUnitFunc;
  onSubmit: (data: CurveParameters) => void;
  onReset: () => void;
}

const numberInputStep = 0.01;
const min = 0;
const max = 1_000;
const validateMoreThanZero = (value: number) =>
  value > 0 || "Значение должно быть больше 0";
const getPopupPosition = (windowWidth: number) => {
  const bottomLeft = {
    popupPlacement: "bottomLeft",
    popupAlign: {
      offset: [0, 12],
    },
  };
  const rightTop = {
    popupPlacement: "rightTop",
    popupAlign: {
      offset: [12, 0],
    },
  };

  return windowWidth > 1_000 ? rightTop : bottomLeft;
};

const Form = ({
  unit,
  // materialThickness,
  // curveAngle,
  // curveInsideRadius,
  // curveOutsideRadius,
  // cutWidth,
  // cutDepth,
  setUnit,
  onSubmit,
  onReset,
}: FormProps) => {
  const [state, setState] = useState({
    ...getPopupPosition(window.innerWidth),
  });
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialThickness: 0,
      curveInsideRadius: 0,
      curveOutsideRadius: 0,
      curveAngle: 90,
      cutWidth: 0,
      cutDepth: 0,
    },
  });
  const watchThickness = watch("materialThickness");

  /**
   *
   * @param e
   */
  const onBlurThickness = (e: React.FocusEvent<HTMLInputElement>) => {
    const thick = Number(e.target.value);

    const inside = getValues("curveInsideRadius");
    setValue("curveOutsideRadius", inside + thick);

    const outside = getValues("curveOutsideRadius");
    setValue("curveInsideRadius", outside - thick);
  };

  /**
   *
   * @param e
   */
  const onBlurOutsideRadius = (e: React.FocusEvent<HTMLInputElement>) => {
    const outside = Number(e.target.value);
    const thick = getValues("materialThickness");

    setValue("curveInsideRadius", outside - thick);
  };

  /**
   *
   * @param e
   */
  const onBlurInsideRadius = (e: React.FocusEvent<HTMLInputElement>) => {
    const inside = Number(e.target.value);
    const thick = getValues("materialThickness");

    setValue("curveOutsideRadius", inside + thick);
  };

  /**
   *
   */
  const throttled = throttle(
    () => {
      setState((prev) => ({
        ...prev,
        ...getPopupPosition(window.innerWidth),
      }));
    },
    1_000,
    { leading: false }
  );

  const resizeListener = useCallback(throttled, [throttled]);
  const lengthUnit = ShortUnitName[unit];

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [resizeListener]);

  return (
    <form
      className="wood-curve-calculator__form wood-curve-calculator__child"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
    >
      <Label>Единицы измерения</Label>
      <Select
        onChange={(e) => {
          setUnit(e.target.value as Unit);
        }}
      >
        {UnitOptions.map(({ value, text }) => (
          <option
            key={value}
            value={value}
          >
            {text}
          </option>
        ))}
      </Select>

      <Label required>Толщина материала, {lengthUnit}</Label>
      <Tooltip
        popupPlacement={state.popupPlacement}
        popupAlign={state.popupAlign}
        popup={
          <TooltipPopup
            img={{
              backgroundPositionY: "center",
              backgroundSize: "168%",
              backgroundPositionX: "110%",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${materialThicknessPic})`,
            }}
          />
        }
      >
        <InputNumber
          step={numberInputStep}
          error={errors?.materialThickness?.message}
          {...register("materialThickness", {
            valueAsNumber: true,
            required: getValidationRule("required", true),
            min: getValidationRule("min", min),
            max: getValidationRule("max", 250),
            validate: validateMoreThanZero,
          })}
          onBlur={onBlurThickness}
        />
      </Tooltip>

      <Label required>Угол изгиба, &deg;</Label>
      <Tooltip
        popupAlign={state.popupAlign}
        popupPlacement={state.popupPlacement}
        popup={
          <TooltipPopup
            img={{
              backgroundImage: `url(${curveAnglePic})`,
            }}
          />
        }
      >
        <InputNumber
          step={numberInputStep}
          error={errors?.curveAngle?.message}
          {...register("curveAngle", {
            valueAsNumber: true,
            required: getValidationRule("required", true),
            min: getValidationRule("min", min),
            max: getValidationRule("max", 360),
            validate: validateMoreThanZero,
          })}
        />
      </Tooltip>

      <Label>Радиус изгиба</Label>
      <Tooltip
        popupAlign={
          state.popupPlacement === "rightTop"
            ? { offset: [12, 20] }
            : { offset: [0, 12] }
        }
        popupPlacement={state.popupPlacement}
        popup={
          <TooltipPopup
            img={{
              backgroundImage: `url(${curveRadiusesPic})`,
            }}
            description={
              <PopupDescription
                rows={[
                  { title: "r", description: "внутренний радиус" },
                  { title: "R", description: "внешний радиус" },
                ]}
              />
            }
          />
        }
      >
        <div className="form__grid_2x2">
          <Label required>Внешний, {lengthUnit}</Label>

          <InputNumber
            step={numberInputStep}
            error={errors?.curveOutsideRadius?.message}
            {...register("curveOutsideRadius", {
              valueAsNumber: true,
              required: getValidationRule("required", true),
              min: getValidationRule("min", min),
              max: getValidationRule("max", max),
              validate: validateMoreThanZero,
            })}
            onBlur={onBlurOutsideRadius}
          />

          <Label required>Внутренний, {lengthUnit}</Label>
          <InputNumber
            step={numberInputStep}
            error={errors?.curveInsideRadius?.message}
            {...register("curveInsideRadius", {
              valueAsNumber: true,
              required: getValidationRule("required", true),
              min: getValidationRule("min", min),
              max: getValidationRule("max", max),
              validate: validateMoreThanZero,
            })}
            onBlur={onBlurInsideRadius}
          />
        </div>
      </Tooltip>

      <Label>Пропил</Label>
      <Tooltip
        popupAlign={
          state.popupPlacement === "rightTop"
            ? { offset: [12, 20] }
            : { offset: [0, 12] }
        }
        popupPlacement={state.popupPlacement}
        popup={
          <TooltipPopup
            img={{
              backgroundImage: `url(${cutParametersPic})`,
            }}
            description={
              <PopupDescription
                rows={[
                  {
                    title: "w",
                    description:
                      "ширина, как правило принимается за толщину пильного диска",
                  },
                  {
                    title: "h",
                    description: "глубина.",
                  },
                ]}
              />
            }
          />
        }
      >
        <div className="form__grid_2x2">
          <Label required>Ширина, {lengthUnit}</Label>
          <InputNumber
            step={numberInputStep}
            error={errors?.cutWidth?.message}
            {...register("cutWidth", {
              valueAsNumber: true,
              required: getValidationRule("required", true),
              min: getValidationRule("min", min),
              max: getValidationRule("max", max),
              validate: validateMoreThanZero,
            })}
          />

          <Label>Глубина, {lengthUnit}</Label>
          <InputNumber
            step={numberInputStep}
            error={errors?.cutDepth?.message}
            {...register("cutDepth", {
              valueAsNumber: true,
              min: getValidationRule("min", min),
              max: getValidationRule("max", watchThickness),
              validate: validateMoreThanZero,
            })}
          />
        </div>
      </Tooltip>

      <Button type="submit">Рассчитать</Button>
      <Button type="reset">Сбросить</Button>
    </form>
  );
};

export default Form;
