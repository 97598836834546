import { Unit } from "./units";import { type Cell, type Width, type Height } from "./hooks/useSVGPlan/type";
export type SelectOption<V> = {
  value: V;
  text: string;
};

/**
 *
 */
export enum MarkingPosition {
  cutCenter = "cut_center",
  cutEdge = "cut_edge",
}

export const MarkingPositionTitle: Record<MarkingPosition, string> = {
  [MarkingPosition.cutCenter]: "центр пропила",
  [MarkingPosition.cutEdge]: "край пропила",
};

export const MarkingPositionOptions: SelectOption<MarkingPosition>[] =
  Object.values(MarkingPosition).map((value) => ({
    value,
    text: MarkingPositionTitle[value],
  }));

export type Marking = {
  points: number[];
  startPosition: number;
  markingPosition: MarkingPosition;
};

/**
 *
 */
export enum CurveParameter {
  curveInsideRadius = "curveInsideRadius",
  curveOutsideRadius = "curveOutsideRadius",
  materialThickness = "materialThickness",
  curveAngle = "curveAngle",
  cutWidth = "cutWidth",
  cutDepth = "cutDepth",
}

/**
 *
 */
export type CurveParameters = {
  [CurveParameter.curveInsideRadius]: number;
  [CurveParameter.curveOutsideRadius]: number;
  [CurveParameter.materialThickness]: number;
  [CurveParameter.curveAngle]: number;
  [CurveParameter.cutWidth]: number;
  [CurveParameter.cutDepth]: number;
};

/**
 *
 */
export enum CutParameter {
  countOfCuts = "countOfCuts",
  insideCurveLength = "insideCurveLength",
  outsideCurveLength = "outsideCurveLength",
  realOutsideCurveLength = "realOutsideCurveLength",
  intervalWidth = "intervalWidth",
}

/**
 *
 */
export type CutParameters = {
  [CutParameter.countOfCuts]: number;
  [CutParameter.insideCurveLength]: number;
  [CutParameter.outsideCurveLength]: number;
  [CutParameter.realOutsideCurveLength]: number;
  [CutParameter.intervalWidth]: number;
};

/**
 *
 */
export type SVGParameters = {
  width: Width;
  height: Height;
  grid: Cell[];
};

/**
 *
 */
export type CurveCalculatorState = {
  language: unknown;
  unit: Unit;
  isCalculated: boolean;
  curveParametersWasChanged: boolean;
  curveParameters: CurveParameters;
  marking: Marking;
  cutParameters: CutParameters;
  svgParameters: SVGParameters;
};

export type SetUnitFunc = (u: Unit) => void;
export type SetMarkingParameterFunc = <T extends keyof Marking>(
  name: T,
  value: Marking[T]
) => void;
