import {
  type SetMarkingParameterFunc,
  type CutParameters,
  type Marking as MarkingType,
} from "../type";
import { Unit, ShortUnitName } from "../units";
import Marking from "../Marking";
import "./style.scss";

interface ResultProps extends CutParameters {
  unit: Unit;
  marking: MarkingType;
  setMarkingParameter: SetMarkingParameterFunc;
}

const Result = ({
  countOfCuts,
  intervalWidth,
  realOutsideCurveLength,
  insideCurveLength,
  outsideCurveLength,
  unit,
  marking,
  setMarkingParameter,
}: ResultProps) => {
  const unitName = ShortUnitName[unit];

  return (
    <div className="wood-curve-calculator__result wood-curve-calculator__child">
      <div className="result__cut-parameters">
        Необходимо <strong>{countOfCuts} пропилов</strong> с расстоянием{" "}
        <strong>
          {intervalWidth}
          {unitName}
        </strong>{" "}
        между ними
      </div>

      <div className="result__curve-lengths">
        <div>Внутренняя длина изгиба:</div>
        <div>
          <strong>
            {insideCurveLength}
            {unitName}
          </strong>
        </div>
        <div>Внешняя длина изгиба:</div>
        <div>
          <strong>
            {outsideCurveLength}
            {unitName}
          </strong>
        </div>
        <div>Реальная внешняя длина:</div>
        <div>
          <strong>
            {realOutsideCurveLength}
            {unitName}
          </strong>
        </div>
      </div>

      <Marking
        {...marking}
        setMarkingParameter={setMarkingParameter}
        unit={unit}
      />
    </div>
  );
};

export default Result;
